/***************************** HERO **********************************/
// Animation
export const HERO_TITLE_PLACEHOLDER = 'Sell your'
export const HERO_SUBTITLE = 'Power your video store with Vidzing'

/***************************** VIDEO **********************************/
export const VIDEO_DESCRIPTION =
  'Monetize your content effortlessly and maximize profits with our all-in-one video platform!'

/*************************** Features *********************************/
export const FEATURES_TITLE = 'Build your online store'
export const FEATURES_SUBTITLE =
  'Creating your one-of-a-kind online video store has never been easier, faster or more scalable. ' +
  'Whatever video you make – we’ve got the perfect platform for you to monetise it.'

/*************************** Creator options *********************************/
export const CREATOR_OPTIONS_TITLE = 'We’ll take care of the tech, while you create the magic!'
export const CREATOR_OPTIONS_SUBTITLE =
  'Stream live, virtual, pre-record or sell on demand. You call the shots, and keep all your sales.'

/*************************** Testimonials *********************************/
export const TESTIMONIALS_TITLE = 'Trusted by thousands of users'
export const TESTIMONIALS_SUBTITLE =
  'Meet our amazing users and hear what they’ve got to say! From newbies to season pros, everyone loves our hands-on support and how effortlessly simple we are to use! '
export const TESTIMONIALS_TEXT_HEADER = 'Creator First'

/*************************** Sign up *********************************/
export const SIGN_UP_TITLE = 'Let’s get started'
export const SIGN_UP_SUBTITLE = 'Are you ready?'
