import { NavigationInterface } from '@shared/interfaces/layout/navigation.interface'
import IconsRender from '@features/_ui/icons-render'
import styles from './styles.module.scss'

export default function NavigationSocial({ title, menu }: NavigationInterface): JSX.Element {
  const renderIconsLink = menu.map((menu) => (
    <a key={menu.label} href={menu.path} target="_blank" rel="noreferrer">
      <IconsRender icon={menu.icon} />
    </a>
  ))

  return (
    <div className={styles.social}>
      <div className={styles.wrapper}>
        <h5>{title}</h5>
        <ul>{renderIconsLink}</ul>
      </div>
    </div>
  )
}
