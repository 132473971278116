import { DirectionsEnum } from '@shared/enums/layout/directions.enum'
import styles from './styles.module.scss'

export interface Props {
  direction: DirectionsEnum
}
export default function ArrowIcon({ direction }: Props): JSX.Element {
  const arrowStyles = [styles.arrow, styles[direction]].join(' ')
  return (
    <svg
      className={arrowStyles}
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0117 9.25171H2V7H20.0117V9.25171Z"
        fill="#53E9B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33653 8.00001L9.29458 2.04196L7.70336 0.449768L0.949215 7.20391C0.844584 7.30831 0.761573 7.43232 0.704934 7.56884C0.648295 7.70537 0.619141 7.85172 0.619141 7.99952C0.619141 8.14733 0.648295 8.29368 0.704934 8.43021C0.761573 8.56673 0.844584 8.69074 0.949215 8.79513L7.70336 15.5503L9.29458 13.9581L3.33653 8.00001Z"
        fill="#53E9B2"
      />
    </svg>
  )
}
