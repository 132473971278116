import Link from 'next/link'
import { NavigationInterface } from '@shared/interfaces/layout/navigation.interface'
import styles from './styles.module.scss'
import RenderIf from '@utils/render-if'
import ChevronIcon from '@ui/icons/chevron'
import { DirectionsEnum } from '@shared/enums/layout/directions.enum'
import { AnimatePresence, motion } from 'framer-motion'
import useComponentVisible from '@utils/use-component-visible'
import UseIsMobile from '@hooks/use-is-mobile'

export default function NavigationMenuItem({
  menu,
  title,
  isAccordion = false,
  optionsClasses,
  path,
}: NavigationInterface): JSX.Element {
  const isCta = path !== undefined
  const { handleToggleComponent, isComponentVisible, ref, refTrigger } = useComponentVisible(false)
  const { isMobile } = UseIsMobile()
  const animation = isMobile
    ? {
        initial: { opacity: 0, y: 0 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 0 },
      }
    : {
        initial: { opacity: 0, y: -30 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -30 },
      }

  const renderMenu = menu.map((menu) => {
    if (menu.onClick) {
      return (
        <li key={menu.label}>
          <button onClick={menu.onClick}>{menu.label}</button>
        </li>
      )
    }
    return menu.external ? (
      <li key={menu.label}>
        <a href={menu.path} target="_blank" rel="noreferrer">
          {menu.label}
        </a>
      </li>
    ) : (
      <li key={menu.label}>
        <Link href={menu.path}>
          <a>{menu.label}</a>
        </Link>
      </li>
    )
  })

  const handleToggleMenu = () => {
    handleToggleComponent()
  }

  const renderCta = isCta ? (
    <Link href={path}>
      <a>
        <h5>{title}</h5>
      </a>
    </Link>
  ) : (
    <button onClick={handleToggleMenu} ref={refTrigger}>
      <h5>
        {title}
        <RenderIf isTrue={isAccordion}>
          <ChevronIcon direction={isComponentVisible ? DirectionsEnum.UP : DirectionsEnum.DOWN} />
        </RenderIf>
      </h5>
    </button>
  )

  return (
    <div className={styles.menu}>
      {renderCta}
      <RenderIf isTrue={!isCta}>
        <AnimatePresence mode={'wait'}>
          {(isComponentVisible || !isAccordion) && (
            <motion.div {...animation} ref={ref} className={optionsClasses}>
              <motion.ul className={styles.list}>{renderMenu}</motion.ul>
            </motion.div>
          )}
        </AnimatePresence>
      </RenderIf>
    </div>
  )
}
