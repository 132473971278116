import styles from './styles.module.scss'
import ArrowIcon from '@features/_ui/icons/arrow'
import { DirectionsEnum } from '@shared/enums/layout/directions.enum'
import Hyperlink from '@features/_ui/cta/hyperlink'
import { HyperLinksInterface } from '@shared/interfaces/layout/hyper-links.interface'
import RenderIf from '@utils/render-if'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { useAppSelector as useSel } from '@root/store'

export interface Props {
  ctaOne: HyperLinksInterface
  ctaTwo?: HyperLinksInterface
  isCompact?: boolean
}

export default function SignUp({ ctaOne, ctaTwo, isCompact = false }: Props): JSX.Element {
  // Flags
  const isNewLandingPageTextMarch2024Enabled = useSel(
    selectAppFeatureFlagStatusByName('flagNewLandingPageTextMarch2024'),
  )

  const styleToUse = isNewLandingPageTextMarch2024Enabled ? styles.ctaV2 : styles.cta

  return (
    <div className={styles.signUp}>
      <RenderIf isTrue={ctaOne !== undefined}>
        <Hyperlink
          className={`${styles.cta} ${isCompact ? styles.small : ''}`}
          label={ctaOne.label}
          path={ctaOne.path}
          variant={'primary'}
          external={ctaOne.external}
          disclaimer={ctaOne?.disclaimer}
        />
      </RenderIf>
      <RenderIf isTrue={ctaTwo !== undefined}>
        <Hyperlink
          className={styleToUse}
          label={ctaTwo?.label}
          path={ctaTwo?.path}
          variant={'secondary'}
          external={ctaTwo?.external}
          icon={<ArrowIcon direction={DirectionsEnum.RIGHT} />}
          iconPosition={'right'}
          disclaimer={ctaTwo?.disclaimer}
        />
      </RenderIf>
    </div>
  )
}
