export enum ColoursEnum {
  ACCENT_ENERGY = 'accentEnergy',
  EARTH = 'earth',
  ENERGY_DARK = 'energyDark',
  ENERGY_LIGHT = 'energyLight',
  OFF_BLACK = 'offBlack',
  OFF_WHITE = 'offWhite',
  PLAYFUL = 'playful',
  SKY = 'sky',
}
