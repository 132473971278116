import style from './common.module.css'
import { ButtonWrapper } from '../../_buttons'
import { SMALL_GRAPHIC_LOGO, TEXT_LOGO, VIDZING_HOME_PAGE } from '@root/constants'
import { HyperlinkTargetEnum } from '@shared/enums/layout/hyperlink.enum'

export type Props = {
  className?: string
  variant?: 'graphic' | 'text' | 'both'
  path?: string
  target?: HyperlinkTargetEnum
}

export function VidzingLogo({
  className = style.defaultWidth,
  variant = 'both',
  path = VIDZING_HOME_PAGE,
  target = HyperlinkTargetEnum.SELF,
}: Props): JSX.Element {
  return (
    <ButtonWrapper href={path} target={target} className={[style.wrapper, className].join(' ')}>
      {(variant === 'both' || variant === 'graphic') && (
        <img
          alt={'Vidzing Logo'}
          className={[style.graphicLogo, style[variant]].join(' ')}
          draggable={false}
          src={SMALL_GRAPHIC_LOGO}
          width="57"
          height="64"
        />
      )}
      {(variant === 'both' || variant === 'text') && (
        <img
          alt={'Vidzing Logo'}
          className={[style.textLogo, style[variant]].join(' ')}
          draggable={false}
          src={TEXT_LOGO}
          width="140"
          height="43"
        />
      )}
    </ButtonWrapper>
  )
}
