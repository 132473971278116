import { useEffect, useState } from 'react'
import UseWindowSize from '@hooks/use-window-size'

export default function UseIsMobile() {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState<boolean>(false)
  const windowsSize = UseWindowSize()

  useEffect(() => {
    setIsMobile(window !== undefined && window.innerWidth < 768)
    setIsTablet(window !== undefined && window.innerWidth < 1024)
  }, [windowsSize])

  return { isTablet, isMobile }
}
