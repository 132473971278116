import { NavigationInterface } from '@shared/interfaces/layout/navigation.interface'
import { HyperLinksInterface } from '@shared/interfaces/layout/hyper-links.interface'
import { IconsEnum } from '@shared/enums/layout/icons.enum'
import { UrlPathsEnum } from '@shared/enums/layout/url-paths.enum'
import {
  CREATOR_SIGN_UP_DISCLAIMER,
  HELP_VIDZING_EMAIL_LINK,
  HELP_VIDZING_LINK,
} from '@shared/constants/root.constants'

export const FOOTER_MENU_NAVIGATION: NavigationInterface[] = [
  // {
  //   title: 'Features',
  //   isAccordion: true,
  //   menu: [
  //     {
  //       label: 'Custom branding',
  //       path: '/',
  //       external: false,
  //     },
  //     {
  //       label: 'All-in-one',
  //       path: '/',
  //       external: false,
  //     },
  //     {
  //       label: 'Endless content types',
  //       path: '/',
  //       external: false,
  //     },
  //     {
  //       label: 'Video first',
  //       path: '/',
  //       external: false,
  //     },
  //     {
  //       label: 'Flexible selling options',
  //       path: '/',
  //       external: false,
  //     },
  //   ],
  // },
  {
    title: 'Company',
    isAccordion: false,
    menu: [
      {
        label: 'Help',
        external: false,
        path: HELP_VIDZING_EMAIL_LINK,
      },
      {
        label: 'House Rules',
        path: '/house-rules',
        external: false,
      },
      {
        label: 'Contact Us',
        path: HELP_VIDZING_EMAIL_LINK,
        external: false,
      },
      // Was previously on hubspot. TODO: add to a vidzing hosted page
      // {
      //   label: 'Accessibility Statement ',
      //   path: '',
      //   external: true,
      // }
    ],
  },
  {
    title: 'Creator',
    isAccordion: false,
    menu: [
      {
        label: 'Become a Creator',
        path: UrlPathsEnum.CREATOR_SIGN_UP,
        external: false,
      },
      {
        label: 'Pricing',
        path: UrlPathsEnum.CREATOR_PRICING,
        external: false,
      },
      {
        label: 'Blog',
        path: UrlPathsEnum.BLOG,
        external: true,
      },
      // {
      //   label: 'Case Studies',
      //   path: '',
      //   external: true,
      // },
    ],
  },
]

export const SOCIAL_NAVIGATION: NavigationInterface = {
  title: 'Follow us',
  menu: [
    {
      label: 'Facebook icon',
      path: 'https://www.facebook.com/Vidzing',
      external: true,
      icon: IconsEnum.FACEBOOK,
    },
    {
      label: 'Instagram icon',
      path: 'https://www.instagram.com/vidzing.tv/',
      external: true,
      icon: IconsEnum.INSTAGRAM,
    },
    {
      label: 'Linkedin icon',
      path: 'https://nz.linkedin.com/company/vidzing',
      external: true,
      icon: IconsEnum.LINKEDIN,
    },
  ],
}

export const CTA_ONE: HyperLinksInterface = {
  label: 'Start free trial',
  path: UrlPathsEnum.CREATOR_SIGN_UP,
  external: true,
  disclaimer: CREATOR_SIGN_UP_DISCLAIMER,
}
