import { NavigationInterface } from '@shared/interfaces/layout/navigation.interface'
import NavigationMenuItem from '@features/_ui/navigation/menu'
import styles from './styles.module.scss'
import { ReactNode } from 'react'
import RenderIf from '@utils/render-if'

export interface Props {
  menus: NavigationInterface[]
  children?: ReactNode
}

export default function NavigationMenu({ menus, children }: Props): JSX.Element {
  const renderMenus = menus.map((menu) => <NavigationMenuItem key={menu.title} {...menu} />)
  return (
    <nav className={styles.navigation}>
      {renderMenus}
      <RenderIf isTrue={children !== undefined}>{children}</RenderIf>
    </nav>
  )
}
