import styles from './styles.module.scss'
import SignUp from '@features/_ui/page/sign-up'
import { HyperLinksInterface } from '@shared/interfaces/layout/hyper-links.interface'

export interface Props {
  title: string
  subtitle: string
  ctaOne: HyperLinksInterface
  ctaTwo?: HyperLinksInterface
}

export default function SignUpHome({ title, subtitle, ctaOne, ctaTwo }: Props): JSX.Element {
  return (
    <div className={styles.signUp}>
      <div className={styles.header}>
        <span>{subtitle}</span>
        <h4>{title}</h4>
      </div>
      <SignUp ctaOne={ctaOne} ctaTwo={ctaTwo} />
    </div>
  )
}
