import { IconsEnum } from '@shared/enums/layout/icons.enum'
import { ColoursEnum } from '@shared/enums/layout/colours.enum'
import { SequenceTextColourInterface } from '@shared/interfaces/layout/hero.interface'
import { FeatureInterface } from '@shared/interfaces/layout/features.interface'
import { CreatorTypesInterface } from '@shared/interfaces/layout/creator-types.interface'
import { TestimonialInterface } from '@shared/interfaces/layout/testimonial.interface'
import { SliderSettingsInterface } from '@shared/interfaces/layout/slider.interface'
import { LogosInterface } from '@shared/interfaces/layout/logos.interface'
import { HyperLinksInterface } from '@shared/interfaces/layout/hyper-links.interface'
import { UrlPathsEnum } from '@shared/enums/layout/url-paths.enum'
import { LogoShapeEnum } from '@shared/enums/layout/logos.enum'
import { CREATOR_SIGN_UP_DISCLAIMER } from '@shared/constants/root.constants'
import { VideoRenderInterface } from '@shared/interfaces/layout/video.interface'

/************************** Hero ***************************/
// Title sequence delay
export const SEQUENCE_DELAY = 1500
// Colour animation
export const HERO_TITLE: SequenceTextColourInterface[] = [
  {
    title: 'videos online',
    colour: ColoursEnum.EARTH,
  },
  {
    title: 'live streams',
    colour: ColoursEnum.SKY,
  },
  {
    title: 'webinars',
    colour: ColoursEnum.PLAYFUL,
  },
  {
    title: 'podcasts',
    colour: ColoursEnum.ACCENT_ENERGY,
  },
]

export const START_FREE_TRIAL_CTA: HyperLinksInterface = {
  label: 'Start free trial',
  path: UrlPathsEnum.CREATOR_SIGN_UP,
  external: true,
  disclaimer: CREATOR_SIGN_UP_DISCLAIMER,
}

export const GET_STARTED_CTA: HyperLinksInterface = {
  label: 'Get started',
  path: UrlPathsEnum.CREATOR_SIGN_UP,
  external: true,
  disclaimer: CREATOR_SIGN_UP_DISCLAIMER,
}

export const BOOK_DEMO_CTA: HyperLinksInterface = {
  label: 'Book a demo',
  path: UrlPathsEnum.BOOK_DEMO,
  external: true,
}

export const CHAT_WITH_EXPERT_CTA: HyperLinksInterface = {
  label: 'Chat with expert',
  path: UrlPathsEnum.BOOK_DEMO,
  external: true,
}

/***************************** VIDEO **********************************/
export const VIDEO =
  'https://vidzing-documents.s3.ap-southeast-2.amazonaws.com/vidzing/Vz+Dreams+Hook+v2.mp4'

/***************************** Features **********************************/
export const FEATURES_TITLE_COLOUR = ColoursEnum.OFF_WHITE
export const FEATURES: FeatureInterface[] = [
  {
    title: 'Custom branding',
    description: 'Categorise and highlight content with your own Netflix-like video library.',
    icon: IconsEnum.PEOPLE_THINKING,
  },
  {
    title: 'All-in-one:',
    description: 'Seamless payments, ticketing, reporting and analytics.',
    icon: IconsEnum.TABLET_WITH_SPEAKER,
  },
  {
    title: 'Endless Content Types:',
    description: 'Video-on-demand, live-stream, zoom, podcasts, one-on-one video calls.',
    icon: IconsEnum.GRAPH_GROWING_ICON,
  },
  {
    title: 'Video First:',
    description: 'Upload, host, store and securely stream with a broadcast quality video player.',
    icon: IconsEnum.TABLET_WITH_SPEAKER,
  },
  {
    title: 'Flexible Selling Options:',
    description:
      'Buy, rent, bundle, subscribe. Global reach, stream globally and accept multiple currencies.',
    icon: IconsEnum.IDEA_ICON,
  },
]

/***************************** Creator options **********************************/
export const CREATOR_OPTIONS: CreatorTypesInterface[] = [
  {
    image: '/_creator-options/creator-type-educator-1.png',
    title: 'Educator:',
    description:
      'Create your very own knowledge business with our all-in-one video monetization platform designed for educators. Teach virtual classes, offer courses and provide coaching sessions to a global audience. Easily attach documents and other media to supplement your video content.',
    path: UrlPathsEnum.EDUCATOR,
    colour: ColoursEnum.ENERGY_LIGHT,
  },
  {
    image: '/_creator-options/creator-type-content-creator-1.png',
    title: 'Content Creator:',
    description:
      'Monetize your audience with our cutting-edge video platform for content creators, TikTokers, YouTubers and Influencers. Engage and inspire your audience with premium content, virtual classes, offer specialized courses or easily create a subscription membership site.',
    path: UrlPathsEnum.CONTENT_CREATOR,
    colour: ColoursEnum.EARTH,
  },
  {
    image: '/_creator-options/creator-type-livestream-1.png',
    title: 'Live Streaming:',
    description:
      'Sell access to live streams, control replay windows, and keep selling after the event. Add VIP packages for an enhanced experience. Broadcast quality and adaptive streaming gives optimal viewing on any device, even with limited bandwidth. Maximize your reach and revenue potential.',
    path: UrlPathsEnum.LIVE_STREAMING,
    colour: ColoursEnum.SKY,
  },
  {
    image: '/_creator-options/creator-type-business-1.png',
    title: 'Business Channels:',
    description:
      'Create your own custom-branded channel to inspire your team or engage customers. Whether it’s for employee training and onboarding or a premium marketing channel or a customer knowledge center, share your content the way you want. Public, private or fully locked down and invite only.',
    path: UrlPathsEnum.BUSINESS_CHANNELS,
    colour: ColoursEnum.PLAYFUL,
  },
]

export const CREATOR_OPTIONS_SLIDER_CONFIGURATION: SliderSettingsInterface = {
  showButtons: true,
  showButtonsInMobile: true,
  settings: {
    loop: false,
    grabCursor: true,
    spaceBetween: 8,

    pagination: {
      clickable: true,
      showPagination: true,
    },
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  },
}

/********************************** Testimonials **************************************/
export const TESTIMONIALS_TITLE_COLOUR = ColoursEnum.OFF_WHITE

// Removed images for now as we do not have images from our testimonials. Component will only render image if image is provided.
export const TESTIMONIALS: TestimonialInterface[] = [
  {
    title: 'Empowering Success and Earning While I Sleep!',
    paragraph:
      'I made $1000 in the first 24hrs of advertising my Vidzing Channel and this was so exciting to me because it showed me I had value. I had a lot of content on my YouTube channel, but now they are on my Vidzing channel. It was the easiest thing in the world I recommend everyone get Vidzing; they helped me so much. When I was sleeping, I was still earning money, which is an amazing thing because my time is so limited.',
    name: 'Debbie Love',
    role: 'For the Love of Tumbling',
    // image: '/_testimonials/testimonial-three.png',
    colour: ColoursEnum.EARTH,
  },
  {
    title: 'Secure and High-Quality Livestream Experience!',
    paragraph:
      'The customer support team of this platform is truly outstanding. I had a technical issue while trying to stream a movie, and their response was swift and helpful. The representative I spoke with was friendly, patient, and knowledgeable, guiding me through the troubleshooting process step by step. ',
    name: 'Terri Cumiskey',
    role: 'New Zealand Opera', //role: Dir. Marketing & Development
    // image: '/_testimonials/testimonial-two.png',
    colour: ColoursEnum.SKY,
  },
  {
    title: 'Unwavering Support for Digital Stages!',
    paragraph:
      'A super special shout out to the hosts of our online platform - Vidzing! The real MVPs whose unwavering support gave us our first digital stage where we were able to present 10 shows and events over two and a half weeks.',
    name: 'Tate Fountain',
    role: 'Auckland Arts Festival',
    //image: '/_testimonials/testimonial-four.png',
    colour: ColoursEnum.PLAYFUL,
  },
  {
    title: 'Glowing Feedback for Livestream Success!',
    paragraph:
      'Thanks so much for being so flexible with us. We are receiving glowing feedback about the livestream so well done! We received feedback that our clients loved the livestream and it was a joy to watch with no issues. Thank you so much for working with us and turning things around so quickly. I really do appreciate it.',
    name: 'Leanne Fever',
    role: 'Southern Cross Health',
    // image: '/_testimonials/testimonial-one.png',
    colour: ColoursEnum.ENERGY_LIGHT,
  },
  {
    title: 'Effortless Video Uploads and Seamless Payments!',
    paragraph:
      'Being as successful as I was in the first month was surprising I didn’t realise the amount of income I could net right away. I started with a 30 day mental block challenge. I found it really really easy to navigate. I drag-and-drop upload a video and Vidzing took care of it all. From taking their credit card information to making sure payment went through all the way to paying me. It was all hands off and super easy.',
    name: 'Jeff Benson',
    role: 'Mind Body Cheer',
    // image: '/_testimonials/testimonial-one.png',
    colour: ColoursEnum.EARTH,
  },
]

//TODO: The
export const TESTIMONIALS_SLIDER_CONFIGURATION: SliderSettingsInterface = {
  showButtons: true,
  showButtonsInMobile: true,
  settings: {
    navigation: true,
    grabCursor: true,
    loop: false,
    slidesPerView: 1,
    spaceBetween: 8,
    observer: true,
    observeParents: true,
    parallax: true,
    pagination: {
      clickable: true,
      showPagination: true,
    },
    breakpoints: {
      288: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1500: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    },
  },
}

/********************************** Logos carousel **************************************/
export const LOGOS_SPEED = 25
export const LOGOS: LogosInterface[] = [
  {
    company: 'Auckland art festival',
    logo: '/_creator-logos/auckland-art-festival.png',
  },
  {
    company: 'Southern cross',
    logo: '/_creator-logos/southern-cross.png',
  },
  {
    company: 'NZ Opera',
    logo: '/_creator-logos/nz-opera.png',
  },
  {
    company: 'Combat sport network',
    logo: '/_creator-logos/combat-sport.png',
  },
  {
    company: 'City kickboxing',
    logo: '/_creator-logos/city-kickboxing.png',
    shape: LogoShapeEnum.SQUARE,
  },
  {
    company: 'Zambesi',
    logo: '/_creator-logos/zambesi-logo.png',
    shape: LogoShapeEnum.SQUARE,
  },
  {
    company: 'NZFW',
    logo: '/_creator-logos/new-zealand-fashion-week.png',
    shape: LogoShapeEnum.SQUARE,
  },
]
